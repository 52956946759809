/* https://tailwindcss.com/docs/functions-and-directives */
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  @apply border-primary/25 focus:outline-none focus-visible:ring-2 focus-visible:ring-sky-500;
}

html {
  @apply bg-secondary text-primary;
}
